<template>
  <RecordViewWrapper>
    <CardToolbox>
      <SearchHeaderStyle>
        <sdPageHeader title="cash Operations">
          <template #subTitle>
            <a-input
              v-model:value="searchValue"
              @change="onHandleSearch"
              placeholder="Search by Name"
            >
              <template #suffix>
                <sdFeatherIcons type="search" size="16" />
              </template>
            </a-input>
          </template>
          <template #buttons>
            <sdButton
              @click="showModal"
              class="btn-add_new"
              size="default"
              type="primary"
              key="1"
            >
              <a to="#">+ Add New</a>
            </sdButton>
          </template>
        </sdPageHeader>
      </SearchHeaderStyle>
    </CardToolbox>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  @change="onHandleTableChange"
                  :pagination="pagination"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
        <sdModal
          title="Cash operation's information"
          :visible="localState.visible"
          :footer="null"
          :onCancel="handleCancel"
        >
          <div class="project-modal">
            <FormValidationWrap>
              <VerticalFormStyleWrap>
                <a-form
                  name="sDash_validation-form"
                  ref="formRef"
                  @finish="onSubmit"
                  :model="formState"
                  :rules="rules"
                  :layout="formState.layout"
                >
                  <a-row :gutter="25">
                    <a-col :md="12" :xs="12">
                      <a-form-item ref="name" label="Name" name="name">
                        <a-input
                          size="large"
                          v-model:value="formState.name"
                          placeholder="Name"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="12" :xs="12">
                      <a-form-item ref="amount" label="Amount" name="amount">
                        <a-input-number
                          :style="{ width: '100%' }"
                          size="small"
                          v-model:value="formState.amount"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12" :xs="12">
                      <a-form-item name="date" label="Date">
                        <a-date-picker
                          v-model:value="formState.date"
                          :style="{ width: '100%' }"
                          :format="dateFormat"
                        />
                      </a-form-item> </a-col
                    ><a-col :span="12" :xs="12">
                      <a-form-item name="estimatedTime" label="Estimated Time">
                        <a-time-picker
                          size="large"
                          v-model:value="formState.estimatedTime"
                          :style="{ width: '100%' }"
                          :format="dateFormatTime"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item label="Cash" name="cashId">
                        <a-select
                          v-model:value="formState.cashId"
                          placeholder="Cash"
                        >
                          <a-select-option
                            v-for="(cash, index) in CashApi"
                            :key="index"
                            :value="cash.id"
                          >
                            {{ cash.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :span="12">
                      <a-form-item
                        label="Cash Operation"
                        name="cashOperationTypeId"
                      >
                        <a-select
                          v-model:value="formState.cashOperationTypeId"
                          placeholder="Cash Operation"
                        >
                          <a-select-option
                            v-for="(
                              cashOperationType, index
                            ) in CashOperationTypeApi"
                            :key="index"
                            :value="cashOperationType.id"
                          >
                            {{ cashOperationType.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :xs="24"
                      ><a-form-item label="Partner" name="partenaireId">
                        <a-select
                          :size="'small'"
                          v-model:value="formState.partenaireId"
                          placeholder="Please choose partner"
                        >
                          <a-select-option
                            v-for="(partner, index) in partnerApi"
                            :key="index"
                            :value="partner.id"
                            >{{ partner.name }}</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :xs="24" :md="24">
                      <sdButton
                        htmlType="submit"
                        size="full"
                        type="primary"
                        key="submit"
                      >
                        <span v-if="!formState.editionMode">Add new</span>
                        <span v-if="formState.editionMode"
                          >Edit cash operation</span
                        >
                      </sdButton>
                    </a-col>
                  </a-row>
                </a-form>
              </VerticalFormStyleWrap>
            </FormValidationWrap>
          </div>
        </sdModal>
      </a-row>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref } from "vue";
import moment from "moment";

const dateFormat = "YYYY/MM/DD";
const dateFormatTime = "HH:mm";

const columns = [
  {
    title: "Name",
    dataIndex: "cashOperation.name",
    key: "cashOperation.name",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Estimated Time",
    dataIndex: "estimatedTime",
    key: "estimatedTime",
  },
  {
    title: "Amount",
    dataIndex: "cashOperation.amount",
    key: "cashOperation.amount",
  },
  {
    title: "Cash Operation Type",
    dataIndex: "cashOperation.cashOperationTypeName",
    key: "cashOperation.cashOperationTypeName",
  },
  {
    title: "Cash",
    dataIndex: "cashOperation.cashName",
    key: "cashOperation.cashName",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const cashOperations = {
  name: "cashOperations",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    RecordViewWrapper,
    CardToolbox,
    SearchHeaderStyle,
  },
  setup() {
    const { state, dispatch } = useStore();
    const searchValue = ref("");
    const CashApi = computed(() => state.CashApi.data);
    const CashOperationTypeApi = computed(
      () => state.CashOperationTypeApi.data
    );
    var cashOperationPagination = computed(() => state.CashOperationApi.data);
    var CashOperationApi = computed(() => state.CashOperationApi.data.data);
    const isLoading = computed(() => state.CashOperationApi.loading);
    const partnerApi = computed(() => state.partnerApi.data);

    var sortDirections = "ascend";

    var loading = false;
    var formState = reactive({
      layout: "vertical",
      id: 0,
      name: "",
      date: "",
      estimatedTime: "",
      amount: null,
      cashOperationTypeId: null,
      cashId: null,
      partenaireId: null,
      Note: "",
    });
    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });
    const formRef = ref();

    const rules = {
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",
        },
      ],
      date: [
        {
          type: "object",
          required: true,
          message: "Date is required",
          trigger: "change",
        },
      ],
      amount: [
        {
          type: "number",
          required: true,
          message: "Amount is required",
          trigger: "change",
        },
      ],
      cashOperationTypeId: [
        {
          required: true,
          message: "Cash operation type is required",
          trigger: "change",
        },
      ],
      cashId: [
        {
          required: true,
          message: "Cash is required",
          trigger: "change",
        },
      ],
    };

    onMounted(() => {
      dispatch("partnerInfo");
      dispatch("cashInfo");
      dispatch("cashOperationTypeInfo");
      dispatch("cashOperationGetPagedData", pagination.value);
      localState.editionMode = false;
    });
    const onHandleTableChange = (pagination) => {
      pagination = {
        ...pagination,
        sorter: {
          order: sortDirections,
        },
        current: pagination.current,
        OrderBy: sortDirections,
        searchValue: searchValue.value,
      };
      dispatch("cashOperationGetPagedData", pagination);
    };

    var pagination = computed(() =>
      cashOperationPagination.value &&
      cashOperationPagination.value.succeeded == true
        ? {
            current: cashOperationPagination.value.pageNumber,
            total: searchValue.value
              ? cashOperationPagination.value.recordsFiltered
              : cashOperationPagination.value.recordsTotal,
            pageSize: cashOperationPagination.value.pageSize,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
        : {
            current: 1,
            pageSize: 10,
            OrderBy: sortDirections,
            searchValue: "",
            sorter: {
              order: sortDirections,
            },
          }
    );

    const onHandleSearch = () => {
      sortDirections = "ascend";
      pagination = {
        ...pagination,
        current: 1,
        OrderBy: sortDirections,
        pageSize: 10,
        searchValue: searchValue.value,
      };

      dispatch("cashOperationGetPagedData", pagination);
    };

    const showModal = () => {
      formState.editionMode = false;
      delete formState.id;
      formState.name = "";
      formState.date = "";
      formState.estimatedTime = "";
      formState.amount = "";
      formState.cashOperationTypeId = "";
      formState.partenaireId = "";
      formState.cashId = "";
      formState.Note = "";
      localState.visible = true;
    };

    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("cashOperationApiDataDelete", id);
      }
      return false;
    };
    const handleEdit = (cashOperation) => {
      formState.editionMode = true;
      formState.id = cashOperation.id;
      formState.name = cashOperation.name;
      formState.date = moment(cashOperation.date);
      formState.estimatedTime = moment(cashOperation.estimatedTime);
      formState.amount = cashOperation.amount;
      formState.cashOperationTypeId = cashOperation.cashOperationTypeId;
      formState.cashId = cashOperation.cashId;
      formState.partenaireId = cashOperation.partenaireId;
      formState.Note = cashOperation.Note;
      localState.visible = true;
    };

    const dataSource = computed(() =>
      CashOperationApi.value && CashOperationApi.value.length
        ? CashOperationApi.value.map((cashOperation, key) => {
            return {
              key: key + 1,
              cashOperation,
              date: moment(cashOperation.date).format(dateFormat),
              estimatedTime: moment(cashOperation.estimatedTime).format(
                dateFormatTime
              ),
              action: (
                <div class="table-actions">
                  <a-button
                    class="edit"
                    onClick={() => handleEdit(cashOperation)}
                  >
                    <sdFeatherIcons type="edit" cashOperation={14} />
                  </a-button>
                  &nbsp;&nbsp;&nbsp;
                  <a-button
                    class="delete"
                    onClick={() => handleDelete(cashOperation.id)}
                  >
                    <sdFeatherIcons type="trash-2" cashOperation={14} />
                  </a-button>
                </div>
              ),
            };
          })
        : []
    );

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          if (!formState.editionMode) {
            dispatch("cashOperationSubmitData", {
              formState,
            });
          } else {
            dispatch("cashOperationUpdateData", {
              formState,
            });
          }
          onCancel();
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    const handleCancel = () => {
      onCancel();
    };

    const onCancel = () => {
      localState.visible = false;
      formState.editionMode = false;
      localState.update = {};
    };

    return {
      formRef,
      rules,
      onSubmit,
      isLoading,
      handleDelete,
      handleEdit,
      dataSource,
      columns,
      formState,
      localState,
      showModal,
      handleCancel,
      onHandleSearch,
      loading,
      onHandleTableChange,
      searchValue,
      CashApi,
      dateFormat,
      dateFormatTime,
      partnerApi,
      CashOperationTypeApi,
    };
  },
};

export default cashOperations;
</script>
